import {
  Card,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import InventoryIcon from "@mui/icons-material/Inventory";
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import { Box } from "@mui/system";

interface ListItemProps {
  icon: React.ReactNode;
  text: string;
  description: string;
}

const listItems: ListItemProps[] = [
  {
    icon: <AddShoppingCartIcon />,
    text: "Customizable Ecommerce Solutions",
    description:
      "Tailored ecommerce solutions that offer flexibility and customization options for businesses to create unique online stores. These solutions typically include features such as customizable product listings, pricing, and checkout processes to meet the specific needs of each merchant.",
  },
  {
    icon: <CheckroomIcon />,
    text: "Print On Demand Solutions",
    description:
      "Print-on-demand solutions enable businesses to fulfill orders for custom-designed products only when they are ordered by customers. This approach eliminates the need for inventory management and allows for cost-effective production of personalized merchandise, such as apparel, accessories, and promotional items.",
  },
  {
    icon: <LocalShippingIcon />,
    text: "Dropshipping Solutions",
    description:
      "Dropshipping solutions involve a retail fulfillment method where the store does not keep products in stock. Instead, when a customer purchases an item, the store purchases the product from a third party and has it shipped directly to the customer. Dropshipping eliminates the need for inventory management and reduces upfront costs for businesses.",
  },
  {
    icon: <InventoryIcon />,
    text: "Inventory Management Systems",
    description:
      "Inventory management systems help businesses track and manage their stock levels, orders, and sales in real-time. These systems provide tools for inventory tracking, stock replenishment, order fulfillment, and reporting, enabling businesses to optimize their inventory operations and reduce stockouts and overstock situations.",
  },
  // {
  //     icon: <MonetizationOnIcon />,
  //     text: 'Sales CRM',
  //     description: 'Sales CRM systems are software solutions that help businesses manage interactions with current and potential customers. These systems typically include features such as contact management, lead tracking, sales pipeline management, and performance analytics to streamline sales processes and improve customer relationships.'
  // },
  {
    icon: <AccountBalanceIcon />,
    text: "Accounting and Invoicing",
    description:
      "Accounting and invoicing solutions automate financial tasks such as invoicing, billing, expense tracking, and financial reporting. These tools help businesses manage their finances more efficiently, reduce manual errors, and ensure compliance with accounting standards and tax regulations.",
  },
  {
    icon: <ViewInArIcon />,
    text: "Best Web Development - Best 3D Web Designs",
    description:
      "Web development services encompass the design, development, and maintenance of websites and web applications. This expertise focuses on creating visually appealing and user-friendly websites with cutting-edge technologies, including 3D web designs, to enhance user engagement and deliver immersive online experiences.",
  },
];

const About: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<ListItemProps>(null);
  const [selectedDesktopItem, setSelectedDesktopItem] =
    React.useState<ListItemProps>(listItems[0]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  const handleItemClick = (item, index) => {
    if (window.innerWidth <= 600) {
      setOpen(true);
      setSelectedItem(item);
    } else {
      setSelectedIndex(index);
      setSelectedDesktopItem(item);
    }
  };

  const handleUpClick = () => {
    const newIndex =
      selectedIndex > 0 ? selectedIndex - 1 : listItems.length - 1;
    setSelectedIndex(newIndex);
    setSelectedDesktopItem(listItems[newIndex]);
  };

  const handleDownClick = () => {
    const newIndex =
      selectedIndex < listItems.length - 1 ? selectedIndex + 1 : 0;
    setSelectedIndex(newIndex);
    setSelectedDesktopItem(listItems[newIndex]);
  };

  return (
    <section id="about us">
      <div className="about-container">
        <Divider variant="middle" flexItem>
          <Typography variant="h3" style={{ userSelect: "none" }}>
            About Us
          </Typography>
        </Divider>
        <div className="about-services">
          <Typography
            variant="body1"
            component="p"
            style={{ userSelect: "none" }}
          >
            Fakhri Infotech is a leading IT solutions provider dedicated to
            delivering cutting-edge technology solutions tailored to meet the
            unique needs of businesses. With a focus on innovation and
            excellence, we specialize in providing a comprehensive range of
            services to empower businesses in the digital age.
          </Typography>
          <Divider variant="middle" flexItem>
            <Typography variant="h5" style={{ userSelect: "none" }}>
              Our Expertise
            </Typography>
          </Divider>
          <div className="about-services-and-description">
            <div className="about-services-list">
              <List>
                {listItems.map((item, index) => (
                  <ListItem
                    key={index}
                    className={`list-item ${
                      selectedIndex === index ? "list-item-selected" : ""
                    }`}
                  >
                    <ListItemButton
                      onClick={() => handleItemClick(item, index)}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
              >
                <Box className="about-modal">
                  <img
                    src="/pro-table-top.png"
                    className="modal-top-img"
                    alt="modalTop"
                  />
                  <div className="modal-content">
                    <Typography
                      id="keep-mounted-modal-title"
                      variant="h6"
                      style={{ userSelect: "none" }}
                    >
                      {selectedItem?.text || "Default Text"}
                    </Typography>
                    <Typography
                      id="keep-mounted-modal-description"
                      sx={{ mt: 2, userSelect: "none" }}
                    >
                      {selectedItem?.description || "Default Description"}
                    </Typography>
                    <img
                      src="/pro-table-bottom.png"
                      className="modal-bottom-img"
                      alt="modalBottom"
                    />
                  </div>
                </Box>
              </Modal>
            </div>
            <div className="desktop-selected-item">
              <IconButton
                className="up-button"
                onClick={handleUpClick}
                disabled={selectedIndex === 0}
              >
                <ArrowDropUpIcon fontSize="large" />
              </IconButton>
              <Card className="about-card">
                <CardContent>
                  <Typography
                    variant="h5"
                    align="center"
                    style={{ userSelect: "none" }}
                  >
                    {selectedDesktopItem?.text}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ userSelect: "none" }}
                  >
                    {selectedDesktopItem?.description}
                  </Typography>
                </CardContent>
              </Card>
              <IconButton
                className="down-button"
                onClick={handleDownClick}
                disabled={selectedIndex === listItems.length - 1}
              >
                <ArrowDropDownIcon fontSize="large" />
              </IconButton>
            </div>
          </div>
        </div>
        <img
          src="/pro-table-top.png"
          className="about-top-img"
          alt="tableTop"
        />
        <img
          src="/pro-table-bottom.png"
          className="about-bottom-img"
          alt="tableBottom"
        />
      </div>
    </section>
  );
};

export default About;
