// src/components/ParticlesBackground.tsx
import React, { useEffect, useState } from "react";
//import { useTheme } from '@mui/system';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";
import options from "./particleOption";
import HeadText from "./HeadText";
import styled from "@emotion/styled";
import Loader from "./Loader";

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("/FakhriBg.svg");
  background-repeat: no-repeat;
  background-size: cover;
`;

const ParticlesBackground: React.FC = () => {
  //const themeContext = useTheme();

  const [init, setInit] = useState<boolean>(false);
  const [imageLoaded, setImageLoaded] = React.useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  return (
    <>
      <img
        src="./FakhriBg.svg"
        onLoad={() => setImageLoaded(true)}
        style={{ display: "none" }}
        alt="loaderImg"
      />
      {!imageLoaded && (
        <div
          style={{
            height: "100dvh",
            width: "100%",
            backgroundColor: "#1E2D4C",
          }}
        >
          <Loader />
        </div>
      )}

      {imageLoaded && (
        <Background className="fade-in">
          <div className="particles-bg">
            {init && <Particles id="tsparticles" options={options} />}
            <HeadText />
          </div>
        </Background>
      )}
    </>
  );
};

export default ParticlesBackground;
