// src/components/Footer.tsx
import React from "react";
import { Typography, Paper } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <Paper
      square
      elevation={3}
      sx={{ padding: 2, marginTop: "auto", bgcolor: "primary.main" }}
    >
      <Typography
        variant="body2"
        component="p"
        align="center"
        style={{ color: "#fff" }}
      >
        © {new Date().getFullYear()} Fakhri Infotech. All rights reserved.
      </Typography>
    </Paper>
  );
};

export default Footer;
