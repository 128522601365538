import styled from "@emotion/styled";
import { Button, TextField } from "@mui/material";
import React, { useRef, useState } from "react";

const ContactForm: React.FC = () => {
  const GlassButton = styled(Button)({
    background: "#6EB3C4",
    opacity: "50%",
    borderRadius: "5rem",
    marginLeft: "2%",
    color: "black",
  });

  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Message: "",
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const formRef = useRef(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const scriptUrl: string =
    "https://script.google.com/macros/s/AKfycbwkW0xvNUYpfl1rgWcrvCUj4oziD2TH24j4rxAAOa5cIm1YiPYUiplzXyXX8aSKQNt7/exec";

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log(formData);
    const formEle = document.querySelector("form");
    console.log("ref - ", formEle);

    fetch(scriptUrl, { method: "POST", body: new FormData(formRef.current) })
      .then((res) => {
        setIsFormSubmitted(true);
        console.log("SUCCESSFULLY SUBMITTED");
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="form-container">
      {isFormSubmitted ? (
        <div className="thank-you-message">
          Thank You, we will get in touch with you soon!
        </div>
      ) : (
        <form ref={formRef} onSubmit={handleSubmit}>
          <TextField
            id="Name"
            name="Name"
            label="Name"
            variant="filled"
            fullWidth
            margin="normal"
            required
            value={formData.Name}
            onChange={handleChange}
          />
          <TextField
            id="Email"
            name="Email"
            label="Email"
            variant="filled"
            fullWidth
            margin="normal"
            required
            type="email"
            value={formData.Email}
            onChange={handleChange}
          />
          <TextField
            id="Phone"
            name="Phone"
            label="Phone"
            variant="filled"
            fullWidth
            margin="normal"
            required
            value={formData.Phone}
            onChange={handleChange}
          />
          <TextField
            id="Message"
            name="Message"
            label="Message"
            variant="filled"
            fullWidth
            multiline
            rows={4}
            margin="normal"
            required
            value={formData.Message}
            onChange={handleChange}
          />
          <GlassButton type="submit" variant="outlined">
            Send Message
          </GlassButton>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
