import {
  Button,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import InstagramIcon from "@mui/icons-material/Instagram";
import styled from "@emotion/styled";

const SocialMediaContact: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const RoundedButton = styled(Button)({
    borderRadius: "1.5rem",
    margin: "0.5rem",
    padding: "1rem",
    minWidth: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
  });

  const handleWhatsAppClick = () => {
    const whatsappNumber = "+918169131582";
    const defaultMessage = encodeURIComponent(
      "Hello, I want to make an enquiry, looking forward to hearing from you."
    );
    window.open(
      `https://wa.me/${whatsappNumber}?text=${defaultMessage}`,
      "_blank"
    );
  };

  const handleMailClick = () => {
    const emailAddress = "contact@fakhriinfotech.com";
    const subject = encodeURIComponent("Inquiry from Website");
    const body = encodeURIComponent("Hello, I am interested in your services.");
    window.location.href = `mailto:${emailAddress}?subject=${subject}&body=${body}`;
  };

  const handleInstagramClick = () => {
    const instagramUsername = "kh_fakhri.infotech";
    window.open(`https://www.instagram.com/${instagramUsername}`, "_blank");
  };
  return (
    <>
      {isMobile ? (
        <Divider
          flexItem
          variant="fullWidth"
          style={{ paddingTop: 12 }}
          sx={{ borderBottomWidth: 3 }}
          orientation="horizontal"
        />
      ) : (
        <></>
      )}
      <div className="reach-out-container">
        {!isMobile ? (
          <>
            <Typography variant="h6">Reach out Directly</Typography>
            <RoundedButton
              onClick={handleWhatsAppClick}
              className="social-media-icon"
            >
              <WhatsAppIcon fontSize="large" />
              WhatsApp
            </RoundedButton>
            <RoundedButton
              onClick={handleMailClick}
              className="social-media-icon"
            >
              <MailIcon fontSize="large" />
              Mail
            </RoundedButton>
            <RoundedButton
              onClick={handleInstagramClick}
              className="social-media-icon"
            >
              <InstagramIcon fontSize="large" />
              Instagram
            </RoundedButton>
          </>
        ) : (
          <>
            <IconButton aria-label="Whatsapp" onClick={handleWhatsAppClick}>
              <WhatsAppIcon fontSize="large" />
            </IconButton>
            <IconButton aria-label="Mail" onClick={handleMailClick}>
              <MailIcon fontSize="large" />
            </IconButton>
            <IconButton aria-label="Instagram" onClick={handleInstagramClick}>
              <InstagramIcon fontSize="large" />
            </IconButton>
          </>
        )}
      </div>
    </>
  );
};

export default SocialMediaContact;
