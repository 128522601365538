import { ReactLenis } from '@studio-freight/react-lenis';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { useEffect, useRef } from 'react';

type AnimationProps = {
    children: React.ReactNode;
};

const Animation : React.FC<AnimationProps> = ({children}) => {

    const textRef = useRef(null);
    

    // const lenis = useLenis(({ scroll }) => {
        
    // });

    // const scrollAnimate = () => {
    //     gsap.fromTo('.circle1', { y: -150 }, { 
    //         y: 0, 
    //         zIndex: 1,
    //         ease: 'bounce', 
    //         duration: 5,
    //         scrollTrigger:{
    //             trigger: '.circle1',
    //             start: 'top center',
    //             end: 'bottom center',
    //             scrub: true,
    //             markers: true,
    //             toggleActions: 'play pause resume complete'
    //         } 
    //     });
    //     gsap.fromTo('.circle2', { y: -150 }, { 
    //         y: 0, 
    //         ease: 'bounce', 
    //         duration: 2,
    //         scrollTrigger:{
    //             trigger: '.circle2',
    //             start: 'top center',
    //             end: 'bottom center',
    //             scrub: true,
    //             toggleActions: 'play none none none'
    //         } 
    //     });
    // }

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger) 
        gsap.fromTo('.animate_head', { opacity: 0, y: -150 }, { opacity: 1, y: 0, duration: 2 });
    }, []);

    return (
        <ReactLenis root>
            <div ref={textRef}>
                {children}
            </div>
        </ReactLenis>
    );
};

export default Animation;