import React from "react";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

interface CardItemProps {
  title: string;
  description: string;
  matches: boolean;
  IconComponent: React.ElementType; // Add this line
}

const CardItem: React.FC<CardItemProps> = ({
  title,
  description,
  matches,
  IconComponent,
}) => (
  <CardContent>
    <IconComponent fontSize="large" className="service-icon" />
    <Typography
      style={{ userSelect: "none" }}
      variant={matches ? "h4" : "h5"}
      component="h5"
    >
      {title}
    </Typography>
    <Typography style={{ userSelect: "none" }} variant="body1" component="p">
      {description}
    </Typography>
  </CardContent>
);

export default CardItem;
