import React from "react";
import { Typography, Divider } from "@mui/material";
import { GlassCard } from "./GlassCard";
import ContactForm from "./ContactForm";
import SocialMediaContact from "./SocialMediaContact";

const ContactUs: React.FC = () => {
  return (
    <section id="contact us">
      <GlassCard>
        <Divider variant="middle" flexItem>
          <Typography variant="h5">Contact Us</Typography>
        </Divider>
        <div className="split-container">
          <ContactForm />
          <Divider
            variant="fullWidth"
            flexItem
            orientation="vertical"
            style={{ paddingLeft: 10 }}
            sx={{ borderRightWidth: 3 }}
          />
          <SocialMediaContact />
        </div>
      </GlassCard>
    </section>
  );
};

export default ContactUs;
