import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import styled from "@emotion/styled";
import { Divider, Typography, useMediaQuery } from "@mui/material";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import SmartButtonOutlinedIcon from "@mui/icons-material/SmartButtonOutlined";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined";
import CardItem from "./CardProps";

const ServicesContainer = styled.div`
  margin-top: 140px;
`;

const FullHeightGrid = styled(Grid)`
  height: 85vh;
  width: 100%;
  padding: 20px; // Adjust the value as needed

  @media (max-width: 600px) {
    height: 100%;
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #f5f5f5;
  height: 100%;
  width: 100%;
  max-width: 480px;
  margin: auto;

  &:hover {
    -webkit-box-shadow: 0px 0px 105px 3px rgba(45, 255, 196, 0.9);
    -moz-box-shadow: 0px 0px 105px 3px rgba(45, 255, 196, 0.9);
    box-shadow: 0px 0px 105px 3px rgba(45, 255, 196, 0.9);
  }
`;

const Services: React.FC = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <ServicesContainer id="services">
      <Divider variant="middle" flexItem>
        <Typography variant="h3">What We Offer</Typography>
      </Divider>
      <FullHeightGrid container spacing={1} direction="column">
        <Grid item xs={7} sm={7}>
          <StyledCard className="service-item">
            <CardItem
              title="Web Development"
              description="Our experts understand your unique business needs and work on the latest technologies to achieve business goals through corporate websites and microsites."
              matches={matches}
              IconComponent={LaptopMacIcon}
            />
          </StyledCard>
        </Grid>
        <Grid item xs={5} sm={5}>
          <StyledCard className="service-item">
            <CardItem
              title="E-commerce"
              description="Our development team helps you with dynamic ecommerce websites built of platforms like Magento, Woocommerce and Shopify."
              matches={matches}
              IconComponent={AddBusinessIcon}
            />
          </StyledCard>
        </Grid>
        <Grid item xs={6} sm={6}>
          <StyledCard className="service-item">
            <CardItem
              title="App Development"
              description="Our mobile app development team creates beautiful apps that will enrich the future of your digital business."
              matches={matches}
              IconComponent={PhoneIphoneIcon}
            />
          </StyledCard>
        </Grid>
        <Grid item xs={6} sm={6}>
          <StyledCard className="service-item">
            <CardItem
              title="UI UX"
              description="Our designers re-imagine and re-define user experiences across platforms and digital ecosystems giving you superior look & usability."
              matches={matches}
              IconComponent={SmartButtonOutlinedIcon}
            />
          </StyledCard>
        </Grid>
        <Grid item xs={5} sm={5}>
          <StyledCard className="service-item">
            <CardItem
              title="Web & App Maintenance"
              description="We regularly check your website for issues and keep it updated and relevant. This ensures your website is healthy, with continued traffic growth, and better SEO and Google rankings."
              matches={matches}
              IconComponent={ConstructionRoundedIcon}
            />
          </StyledCard>
        </Grid>
        <Grid item xs={7} sm={7}>
          <StyledCard className="service-item">
            <CardItem
              title="Search Engine Optimization"
              description="We make sure that your website reaches the right audience. You can expect your website to always perform well on Google Search with our SEO strategies."
              matches={matches}
              IconComponent={PageviewOutlinedIcon}
            />
          </StyledCard>
        </Grid>
      </FullHeightGrid>
      <div className="custom-shape-divider-top-1707579013">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
            opacity=".25"
            className="shape-fill"
          ></path>
          <path
            d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
            opacity=".5"
            className="shape-fill"
          ></path>
          <path
            d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
    </ServicesContainer>
  );
};

export default Services;
