import { Typography } from "@mui/material";
import React, { useEffect } from "react";

export const HeadText: React.FC = () => {
  useEffect(() => {
    // Text rotation script
    var TxtRotate = function (el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 8) || 1500;
      this.txt = "";
      this.tick();
      this.isDeleting = false;
    };

    TxtRotate.prototype.tick = function () {
      var i = this.loopNum % this.toRotate.length;
      var fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

      var that = this;
      var delta = 150 - Math.random() * 100;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === "") {
        this.isDeleting = false;
        this.loopNum++;
        delta = 50;
      }

      setTimeout(function () {
        that.tick();
      }, delta);
    };

    window.onload = function () {
      var elements = document.getElementsByClassName("txt-rotate");
      for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute("data-rotate");
        var period = elements[i].getAttribute("data-period");
        if (toRotate) {
          new TxtRotate(elements[i], JSON.parse(toRotate), period);
        }
      }
      // INJECT CSS
      var css = document.createElement("style");
      css.type = "text/css";
      css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
      document.body.appendChild(css);
    };
  });

  const animateHead: string = "animate_head";

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "#fff",
        textAlign: "center",
        justifyItems: "center",
      }}
    >
      <Typography
        variant="h4"
        component="div"
        style={{ userSelect: "none" }}
        className={`glow-text ${animateHead}`}
      >
        Hey, There
      </Typography>
      <Typography
        variant="h4"
        component="div"
        style={{ userSelect: "none" }}
        className={`glow-text ${animateHead}`}
      >
        We're
      </Typography>
      <Typography
        variant="h2"
        className={`glow-text ${animateHead}`}
        data-splitting
        data-effect4
        component="h2"
        style={{ userSelect: "none" }}
      >
        Fakhri Infotech
      </Typography>
      <Typography
        className={`txt-rotate ${animateHead}`}
        variant="h4"
        data-period="2000"
        data-rotate='[ "Innovating Solutions for a Digital Future", "Your Strategic Technology Partner" ]'
        style={{ userSelect: "none" }}
      ></Typography>
    </div>
  );
};

export default HeadText;
