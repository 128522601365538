// src/App.tsx
import React, { useState } from "react";
//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Container from "./Components/Container/Container";
import "./App.css";

const App: React.FC = () => {
  const [theme] = useState<"light" | "dark">("light");

  // const toggleTheme = () => {
  //   setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  // };

  // Custom palette for light mode
  const lightPalette = {
    primary: {
      main: "#1E2D4C", // customize primary color
    },
    secondary: {
      main: "#6EB3C4", // customize secondary color
    },
    background: {
      default: "#FFFFFF", // customize background color
    },
  };

  // Custom palette for dark mode
  const darkPalette = {
    primary: {
      main: "#64B5F6", // customize primary color
      contrastText: "#47008F",
    },
    secondary: {
      main: "#FF80AB", // customize secondary color
    },
    background: {
      default: "#121212", // customize background color
      paper: "#1E1E1E", // customize paper (content) background color
    },
  };

  const appliedTheme = createTheme({
    palette: theme === "light" ? lightPalette : darkPalette,
    typography: {
      fontFamily: ["Salsa", "cursive"].join(","),
    },
  });

  return (
    <div className="app">
      <ThemeProvider theme={appliedTheme}>
        <CssBaseline />
        <Navbar />
        <Container />
        <Footer />
      </ThemeProvider>  
    </div>
  );
};

export default App;
