import React from "react";
import Home from "./Home/Home";
import ContactUs from "../ContactUs/ContactUs";
import Animation from "../../Animations/Animation";
import Services from "./Services/Services";
import About from "./About/About";
import Work from "./Work/Work";

const Container: React.FC = () => {
  return (
    <Animation>
      <Home />
      <Services />
      <Work />
      <About />
      <ContactUs />
    </Animation>
  );
};

export default Container;
