import { Divider, Typography } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCards,
  EffectCoverflow,
  Navigation,
  Pagination,
  Parallax,
  Zoom,
} from "swiper/modules";
import "swiper/scss";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/parallax";
import "swiper/css/zoom";
import "swiper/css/effect-coverflow";
import "swiper/scss/effect-cards";

const slides = [
  {
    href: "https://lttraders.vercel.app/",
    src: "/llt-traders.JPG",
    alt: "Slide 1",
    text: "Ecommerce",
  },
  {
    href: "https://www.naqiyahpack.com/",
    src: "/Naqiyah-pack-home.JPG",
    alt: "Slide 2",
    text: "Web Development",
  },
  {
    href: "https://turfed-up.vercel.app/signup",
    src: "/Capture.JPG",
    alt: "Slide 3",
    text: "Mobile App",
  },
  {
    href: "https://turbulent.ca/",
    src: "/Turbulent.JPG",
    alt: "Slide 4",
    text: "3D - Website",
  },
  // Add more slides as needed
];

const Work: React.FC = () => {
  const isMobile = window.innerWidth <= 768;

  return (
    <section id="work" style={{ marginTop: "140px" }}>
      <Divider variant="middle" flexItem>
        <Typography variant="h3" style={{ userSelect: "none" }}>
          Our Work
        </Typography>
      </Divider>

      <div className="swiper-class">
        <Swiper
          spaceBetween={50}
          modules={[
            Navigation,
            Pagination,
            Parallax,
            Zoom,
            EffectCoverflow,
            EffectCards,
          ]}
          slidesPerView={isMobile ? 1 : 2}
          navigation
          pagination={{
            bulletClass: "swiper-pagination-bullet",
            type: "bullets",
            enabled: !isMobile,
          }}
          parallax={true}
          loop={true}
          zoom={true}
          effect={isMobile ? "cards" : "coverflow"}
          cardsEffect={{ perSlideOffset: 20, perSlideRotate: 2 }}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index} className="image-shadow">
              <a href={slide.href} target="_blank" rel="noopener noreferrer">
                <img
                  src={slide.src}
                  alt={slide.alt}
                  style={{ width: "100%", height: "auto", userSelect: "none" }}
                />
              </a>
              <Typography className="swiper-item-text" variant="h6">
                {slide.text}
              </Typography>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Work;
