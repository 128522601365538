import { CardContent } from "@mui/material";
import React, { ReactNode } from "react";

interface MyComponentProps {
  children?: ReactNode;
}

export const GlassCard: React.FC<MyComponentProps> = ({ children }) => {
  return (
    <div className="contact-container">
      <div className="circle1"></div>
      <div className="circle2"></div>
      <CardContent className="glass-card">{children}</CardContent>
    </div>
  );
};
