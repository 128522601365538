import React from "react";

const Loader: React.FC = () => {
  return (
    <div className="loop cubes">
      <div className="item cubes"></div>
      <div className="item cubes"></div>
      <div className="item cubes"></div>
      <div className="item cubes"></div>
      <div className="item cubes"></div>
      <div className="item cubes"></div>
    </div>
  );
};

export default Loader;
